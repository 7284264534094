import React, { Component } from 'react';
import '../assets/styles/main.css';

class Main extends Component{
    render(){
        return(
               <div className="outermost-wrapper">
                    <div className="inner-wrapper">
                        <div className="list">
                            <div className="info-sec">
                                <div className="info-left">  
                                    <img src={ process.env.REACT_APP_LOGO } className="fw-logo" alt="failed to load" />
                                    <br/>
                                    <p className="info-big">
                                            We will take you to future
                                    </p>
                                    <p className="info-small">
                                        Our vision is to create playgrounds for the
                                        <br/>
                                        millennials
                                    </p>
                                </div>
                                <div className="list-sec">
                                    <div className="list-link">
                                        <a href="#ssoldot" id="ssol-link" className="ssol-link" rel="noopener noreferrer"><img src={ process.env.REACT_APP_SSOL_LINK } className="ssol-img" alt="failed to load" /></a>
                                        <a href="#dissolv" id="diss-link" className="ddis-link" rel="noopener noreferrer"><img src={ process.env.REACT_APP_DISS_LINK } className="ddis-img" alt="failed to load" /></a>
                                    </div>
                                </div>
                            </div>
                        </div> 

                        <div className="ssoldot">
                            <div className="ssol-sec" id="ssoldot">
                                <div className="ssol-left">
                                    <img src={ process.env.REACT_APP_SSOL_APP } className="ssol-app" alt="failed to load" />
                                </div>
                                <div className="border-bottom-short"></div>
                                <div className="ssol-right">
                                    <img src={ process.env.REACT_APP_SSOL_LOGO } className="ssol-logo" alt="failed to load" />
                                    <br/>
                                    <span className="ssol-info">
                                        SSOLDOT provides Fashion + Lifestyle 
                                        <br/>
                                        limited editions / collaborations news 
                                        <br/>
                                        around the globe.
                                    </span>
                                    <br/>
                                    <div className="link-btns">
                                        <a href="https://apps.apple.com/kr/app/id1313654699" rel="noopener noreferrer"><img src={ process.env.REACT_APP_IOS_BTN } className="ios-btn" alt="failed to load" /></a>
                                        <a href="https://play.google.com/store/apps/details?id=com.fw.ssoldot&hl=en&gl=US" rel="noopener noreferrer"><img src={ process.env.REACT_APP_AND_BTN } className="and-btn" alt="failed to load"/></a>
                                    </div>
                                    <div className="ssol-history">
                                        <img src={ process.env.REACT_APP_SSOL_ACH } alt="failed to load"/>
                                    </div>
                                    <div className="ssol-archive">
                                        <span className="ssol-a1">
                                            <b>2019. 08. 31</b>
                                            <br/>
                                            ‘App store ‘Today’s App’
                                        </span>
                                        <span className="ssol-a2">
                                            <b>2018. 07. 22</b>
                                            <br/>
                                            'App Store' Lifestyle Category 
                                            <br/>
                                            Rank 1
                                        </span>
                                        <span className="ssol-a3">
                                            <b>2018. 07. 21</b>
                                            <br/>
                                            ‘App store ‘Today’s App’
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="dissolv">
                            <div className="diss-sec" id="dissolv">
                                <div className="diss-left">
                                    <img src={ process.env.REACT_APP_DISS_LOGO } className="diss-logo" alt="failed to load" />
                                    <br/>
                                    <span className="diss-info">
                                        <b>DISSOLV.</b>
                                        <br/>
                                        Creative works by SSOL.
                                    </span>
                                    <br/>
                                    <img src="/assets/img/diss-visit.png" className="diss-visit" alt="failed to load" />
                                    <br/>
                                    <img src={ process.env.REACT_APP_DISS_OUR } className="diss-our" alt="failed to load" />
                                    <br/>
                                    <div className="diss-archive">
                                        <div className="diss-a1">
                                            <b>2019. 09. 26</b>
                                            <br/>
                                            ‘DISSOLV.’ First Exhibition at 
                                            <br/>
                                            MOORSEOUL
                                        </div>
                                        <div className="diss-a2">
                                            <b>2019. 09. 26</b>
                                            <br/>
                                            ‘DISSOLV.’ Artbook published 1st Edition
                                        </div>
                                    </div>
                                </div>
                                <div className="diss-right">
                                    <img src={ process.env.REACT_APP_DISS_PICS } className="diss-pics" alt="failed to load" />
                                </div>
                            </div>
                        </div>
                        <div className="footer">
                            <div className="footer-infos">
                                <div className="f-email">
                                    <a href="mailto: info@futurewalks.com" className="f-email-link" rel="noopener noreferrer">info@futurewalks.com</a>
                                </div>
                                <div className="f-info1">
                                    4F, 31, Nonhyeon-ro 149-gil, Gangnam-gu, Seoul, Republic of Korea
                                    </div>
                                <div className="d-info2">
                                    ⓒFuturewalks Inc. All rights reserved. 2017
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
        );
    }
};
export default Main;