import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Main } from '../pages';

class App extends Component{
    constructor(props){
        super(props);
    }
    render(){
        return(
            <>
            <Route path="/" component={ Main }/>
            </>
        );
    }
}
export default App;